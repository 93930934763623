@import '../../../assets/styles/Common.scss';

.button-generic {
  box-sizing: border-box;
  display: block;
  height: 50px;
  margin: 0;
  padding: 10px 15px;
  background-color: $color-blue;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  line-height: 30px;
  color: #fff;
  border: 0;
  transition: background-color 0.15s ease;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    color: #fff;
    background-color: $color-blue-light;

    &.button-generic_disabled {
      background-color: $color-blue;
    }
  }

  &.button-generic_secondary {
    color: $color-blue;
    border: 1px solid $color-blue;
    background-color: transparent;

    &:hover,
    &:focus,
    &:active {
      color: $color-blue-light;
      border: 1px solid $color-blue-light;
    }
  }

  &.button-generic_cancel {
    color: $color-blue;
    background-color: #fff;

    &:hover,
    &:focus,
    &:active {
      color: $color-blue-light;
    }
  }

  &.button-generic_disabled {
    opacity: 0.6;
    cursor: default;
  }
}

.theme-dark {
  .button-generic {
    background-color: $color-blue-theme-dark;
    color: #333;

    &:hover,
    &:focus,
    &:active {
      color: #333;
      background-color: $color-blue-light-theme-dark;

      &.button-generic_disabled {
        background-color: $color-blue-theme-dark;
      }
    }

    &.button-generic_secondary {
      color: $color-blue-theme-dark;
      border: 1px solid $color-blue-theme-dark;
      background-color: transparent;

      &:hover,
      &:focus,
      &:active {
        color: $color-blue-light-theme-dark;
        border: 1px solid $color-blue-light-theme-dark;
      }
    }

    &.button-generic_cancel {
      color: $color-blue-theme-dark;
      background-color: transparent;

      &:hover,
      &:focus,
      &:active {
        color: $color-blue-light-theme-dark;
        // background-color: $color-grey-light;
      }
    }
  }
}
