@import './Common.scss';

.react-responsive-modal-overlay {
  background-color: rgba(209, 209, 209, 0.52);
}

.react-responsive-modal-modal {
  box-sizing: border-box;
  max-width: 100%;
  max-height: calc(100vh - 80px);
  margin: 20px;
  padding: 30px;
  border-radius: $border-radius-default;
  box-shadow: $box-shadow-default;
  overflow: auto;
  vertical-align: top;

  [tabIndex] {
    outline: none;
  }

  @media screen and (min-width: 480px) {
    margin: 30px;
    padding: 40px 50px;
    vertical-align: middle;

    &.modal-alert {
      width: 415px;
    }
  }
}

.react-responsive-modal-closeButton {
  top: 7px;
  right: 7px;

  svg {
    width: 18px;
    height: 18px;
    fill: $color-blue;
  }
}

.modal__title {
  margin-bottom: 20px;
  font-size: 26px;
  font-weight: 300;
  columns: #333;

  &.reset-margin {
    margin: 0;
  }
}

.modal__title_secondary {
  margin: 20px 0 10px;
  font-size: 20px;
  font-weight: 300;
  columns: #333;
}

.modal-button-single {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  padding-top: 40px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  .button-generic {
    // width: 50%;
    min-width: 280px;
    max-width: 100%;
    margin: 0 auto;
  }
}

.modal-buttons {
  .button-generic {
    width: 100%;
  }
}

.modal-buttons__stack,
.modal-buttons__row {
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  .button-generic {
    width: 100%;

    & + .button-generic {
      margin-top: 10px;
    }
  }
}

.modal-buttons_centered {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .button-generic {
    min-width: 150px;
  }
}

@mixin buttonsRow() {
  display: flex;
  flex-direction: row;
  margin-top: 30px;

  div + div {
    margin: 0;
  }

  div {
    display: flex;
    flex: 1;

    + div {
      margin-left: 30px;
    }

    > .button-generic {
      width: 100%;
    }
  }
}

.modal-buttons__row {
  div + div {
    margin-top: 10px;
  }

  @media screen and (min-width: 480px) {
    &.modal-buttons__row_2 {
      @include buttonsRow();
    }
  }

  @media screen and (min-width: 780px) {
    @include buttonsRow();
  }
}

.modal-notes-image {
  display: none;

  @media screen and (min-width: 640px) {
    display: block;
    position: absolute;
    top: -45px;
    right: -34px;
    z-index: 2;
    width: 184px;
    height: 150px;
  }
}

.theme-dark {
  .react-responsive-modal-overlay {
    background-color: rgba(129, 129, 129, 0.78);
  }

  .react-responsive-modal-modal {
    background-color: $color-grey-dark-theme-dark;
  }

  .modal__title {
    color: #d3d5d6;
  }

  .react-responsive-modal-closeButton {
    svg {
      fill: #fff;
    }
  }

  .modal-button-single {
    border-color: rgba(255, 255, 255, 0.2);
  }
}
