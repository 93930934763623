@import '../../../assets/styles/Modal.scss';

.react-responsive-modal-modal.modal-about {
  max-width: 720px;
}

.modal-about {
  .built-by {
    padding: 20px 0;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    a {
      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
      }
    }

    .user-thumbnail {
      display: none;
    }
  }

  @media screen and (min-width: 480px) {
    .built-by {
      padding: 30px 0 40px;
      margin-bottom: 40px;

      > div {
        display: flex;
        flex-direction: row;
      }

      .vcard {
        flex: 1;

        .user-thumbnail {
          margin-right: 10px;
        }

        & + div {
          position: relative;

          a {
            justify-content: flex-end;
            text-align: right;
          }

          .user-thumbnail {
            margin: 0 0 0 10px;
          }

          &:before {
            content: '';
            position: absolute;
            top: -5px;
            left: -0.5px;
            width: 55px;
            height: 55px;
            transform: rotate(90deg);

            background: 0 100%
              url('../../Toolbar/UsersDrawer/CurrentUser/assets/img_border-dotted.svg')
              repeat-x;
          }
        }
      }

      a {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 20px;
        line-height: 1.2;

        span {
          display: block;
          font-size: 14px;
          color: #333;
          font-weight: 400;
        }
      }

      .user-thumbnail {
        display: flex;
        margin: 0;
      }
    }
  }
}

.theme-dark {
  .modal-about {
    .built-by {
      border-color: rgba(255, 255, 255, 0.2);

      a {
        span {
          color: #fff;
        }

        &:hover,
        &:focus,
        &:active {
          text-decoration: none;
        }
      }
    }
  }
}
