.react-responsive-modal-modal.modal-tips {
  max-width: 720px;

  @media screen and (min-width: 800px) {
    width: 720px;
  }
}

.modal-tips {
  li {
    margin-bottom: 10px;
    list-style: none;
  }

  kbd {
    display: inline-block;
    min-width: 1.7em;
    padding: 0.5em 0.55em;
    border: 1px solid #707070;
    border-radius: 3px;
    font-size: 11px;
    line-height: 1.1;
    text-align: center;

    &:last-of-type {
      margin-right: 3px;
    }
  }

  .plus {
    font-size: 11px;

    & + kbd {
      // margin-right: 5px;
    }
  }

  .or {
    display: inline-block;
    margin: 0 3px;
  }

  .or + kbd {
    // margin-left: 5px;
  }
}
