@import '../../assets/styles/Common.scss';
@import url('https://fonts.googleapis.com/css2?family=Kalam:wght@300&display=swap');

$note-size: 132px;
$note-font: 300 16px/1.35 'Kalam', $font-stack !important;

.note {
  transition: transform 0.2s;
  -webkit-tap-highlight-color: transparent;

  &:hover .button-delete-note {
    display: block;
  }

  .note_editable {
    opacity: 0;
    transition: opacity 0.2s;
  }

  &.note_visible {
    .note_editable {
      opacity: 1;
    }
  }

  &.note_editing {
    z-index: 2147483647 !important;
  }

  &.note_disable-transition {
    transition: none;

    .note_editable {
      transition: none;
    }
  }

  &.note_locked {
    cursor: not-allowed;

    .note_editable {
      opacity: 0.85;
    }

    &:hover .button-delete-note {
      display: none;
    }
  }

  &.note_selected,
  &.note_highlighted {
    .note_editable {
      outline: 1px solid rgba($color: $color-blue, $alpha: 0.5);
    }
  }
}

.theme-dark {
  .note_selected,
  .note_highlighted {
    .note_editable {
      outline-color: #fff;
    }
  }
}

.note_editable {
  display: block;
  position: relative;
  box-sizing: border-box;
  width: $note-size;
  height: $note-size;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
  overflow-x: hidden;
  overflow-y: auto;

  .note_editable__inner {
    display: table;
    max-width: $note-size;
  }

  .note_editable__text {
    box-sizing: border-box;
    display: table-cell;
    vertical-align: middle;
    width: $note-size;
    max-width: $note-size;
    height: $note-size;
    max-height: $note-size;
    padding: 8px;
    font: $note-font;
    text-align: center;
    word-wrap: break-word;
    outline: none;

    img {
      max-width: 100%;
      max-height: 100%;
    }

    // ContentEditable accepts text copied and pasted with styling,
    // so could have a different font or background.
    p,
    span,
    li,
    code,
    blockquote,
    div {
      font: $note-font;
      color: #333 !important;
      background-color: transparent !important;
      text-shadow: none !important;
    }
  }

  // &::after {
  //   content: '';
  //   position: absolute;
  //   top: 16px;
  //   right: 16px;
  //   bottom: 16px;
  //   left: 16px;
  //   pointer-events: none;
  //   user-select: none;
  //   outline: 1px dotted #ccc;
  // }

  // &::-webkit-scrollbar {
  //   // width: 10px;
  //   // visibility: hidden;
  //   width: 0;
  // }

  // &::-webkit-scrollbar {
  // width: 10px;
  // }

  // &::-webkit-scrollbar-track {
  // border-radius: 10px;
  // }

  // &::-webkit-scrollbar-thumb {
  //   border-radius: 5px;
  //   background: rgba(0, 0, 0, 0.2);
  // }

  // &::-webkit-scrollbar-thumb:hover {
  //   background: rgba(0, 0, 0, 0.4);
  // }

  // &::-webkit-scrollbar-thumb:active {
  //   background: rgba(0, 0, 0, 0.6);
  // }
}

.button-delete-note {
  display: none;
  position: absolute;
  top: 0;
  right: 0;

  .button-remove {
    width: 30px;
    padding: 6px 6px 0 0;
    text-align: right;
  }
}

.note-drag-preview {
  // transform: rotate(-2deg);
  position: 'relative';
  z-index: 2147483647;
}

.selection {
  .note {
    transition: none;

    .note_editable {
      transition: none;
    }
  }
}
