@import '../../../assets/styles/Common.scss';

.app-header__logo-separator {
  display: inline-block;
  margin: 0 20px;
  height: 20px;

  svg {
    stroke: #24282b;
  }
}

.theme-dark {
  .app-header__logo-separator {
    svg {
      stroke: #fff;
    }
  }
}
