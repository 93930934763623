@import '../../../../assets/styles/Common.scss';

.theme-toggle {
  $size: 34px;

  margin: 0;
  background-color: $color-blue;
  display: flex;
  width: $size;
  height: $size;
  position: relative;
  overflow: hidden;

  border-radius: $size;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
  -webkit-tap-highlight-color: transparent;

  &:hover,
  &:focus,
  &:active {
    background-color: #3d82d2;
  }

  // Rotate a bit because rounded corners don't respect overflow: hidden,
  // so the moving icons are visible outside the circle.
  transform: rotate(25deg);

  label {
    display: block;
    margin: 0;
    padding: 0;
    width: $size;
    height: $size;
    cursor: pointer;
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }

  .icons {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: rotate(-25deg);
    pointer-events: none;
  }

  .icons__inner {
    position: absolute;
    top: 0;
    left: -33px;
    width: 100px;
    height: 100px;
    transition: transform 0.5s ease;
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    width: $size;
    height: $size;
    transform: translateX(-50%);
  }

  .icon-light {
    top: 0;
  }

  .icon-dark {
    bottom: 0;
  }

  svg {
    fill: #fff;
  }
}

.theme-dark {
  .theme-toggle {
    background-color: $color-grey-dark-theme-dark;

    &:hover,
    &:focus,
    &:active {
      background-color: #616366;
    }
  }
}
