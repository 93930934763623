@import '../../../assets/styles/Common.scss';

.selection {
  position: absolute;
  margin: -1px 0 0 -1px;
  border: 1px dashed rgba($color: $color-blue, $alpha: 0.5);
  border-radius: 4px;
  background: rgba($color: $color-blue, $alpha: 0.05);
  cursor: move;
  overflow: hidden;
}

.selection__inner {
  position: absolute;
  pointer-events: 'none';
}

.selection-box-info {
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: #fff;
  font-size: 12px;
  padding: 10px;
}

.theme-dark {
  .selection {
    border-color: rgba(160, 160, 160, 0.9);
    background: rgba(92, 93, 95, 0.3);
  }
}
