@import '../../../assets/styles/Common.scss';

.board {
  width: 100%;
  position: relative;
  // touch-action: manipulation;
}

.boards-list {
  margin: 40px 30px 0;
  padding-top: 20px;
  border-top: 1px solid #ccc;
}
