.react-responsive-modal-modal.modal-profile {
  min-width: 280px;

  @media screen and (min-width: 480px) {
    min-width: 440px;
  }

  @media screen and (min-width: 600px) {
    min-width: 500px;
    height: 443px;
  }

  @media screen and (min-width: 720px) {
    width: 660px;
  }
}

.react-responsive-modal-modal.modal-secondary {
  min-width: 280px;

  @media screen and (min-width: 480px) {
    width: 440px;
  }

  @media screen and (min-width: 540px) {
    width: 456px;
  }
}

.profile-form__links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.profile__row {
  margin-bottom: 0;
  padding: 15px 0;
  line-height: 20px;
  background: 0 100%
    url('../Toolbar/UsersDrawer/CurrentUser/assets/img_border-dotted.svg')
    repeat-x;

  > span {
    display: block;

    b {
      font-weight: 400;
    }
  }

  @media screen and (min-width: 480px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    > span {
      display: inline;
    }
  }

  &:last-of-type {
    background: none;
  }
}
