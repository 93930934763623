@import '../../../assets/styles/Common.scss';

.tryangly-logo {
  width: 155px;
  height: 30px;
  transition: fill 0.2s ease;

  .logo-icon {
    fill: $color-blue;
  }

  .logo-name {
    fill: #2a2d2f;
  }
}

.theme-dark {
  .tryangly-logo {
    .logo-icon {
      fill: $color-blue-theme-dark;
    }

    .logo-name {
      fill: #fff;
    }
  }
}
