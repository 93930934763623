@import '../../../assets/styles/Common.scss';

.modal-board-size {
  width: 430px;

  .inputs-row {
    display: flex;
    flex-direction: row;

    > div {
      flex: 1;
      height: 5.73em;
    }

    .inputs-row__text {
      min-width: 2em;
      padding-top: 3.1em;

      text-align: center;
      font-size: 14px;
      font-weight: 300;
      color: #6e6e6e;
    }
  }
}
