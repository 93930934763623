@import '../../assets/styles/Common.scss';

.tabs {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 30px;
}

.tab {
  display: block;
  width: 100%;
  padding: 0.5em 1em;
  border-bottom: 1px solid #e4e4e4;
  font-size: 14px;
  font-weight: 300;
  color: #6e6e6e;
  text-align: center;

  &.tab_active {
    font-weight: bold;
    border-color: $color-blue;
    color: $color-blue;
    font-weight: 500;
  }

  &.tab_disabled {
    cursor: default;
  }
}

.theme-dark {
  .tab {
    color: #fff;

    &.tab_active {
      border-color: $color-blue-theme-dark;
      color: $color-blue-theme-dark;
    }
  }
}
