@import '../../../assets/styles/Common.scss';

.board-title {
  margin: 0;
  color: $color-text;
  font-size: 26px;
  font-weight: 200;
  overflow: hidden;

  .editable {
    > div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.editable_editing {
      > div {
        // margin-right: 20px;
        overflow: visible;
      }
    }
  }
}

.theme-dark {
  .board-title {
    color: #fff;
  }
}
