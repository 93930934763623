@import '../../../../assets/styles/Common.scss';

.saved-board-toggle {
  $size: 32px;
  $size-large: 64px;

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: $size;
  height: $size;
  overflow: hidden;
  margin: 0 0 -1px -9px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  -webkit-tap-highlight-color: transparent;

  input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }

  svg {
    fill: #2a2d2f;

    #saved-board-toggle-on {
      display: none;
    }
  }

  &.saved-board-toggle__disabled {
    cursor: default;

    input {
      cursor: default;
    }
  }

  &.saved-board-toggle_profile {
    background-color: $color-blue;
    width: $size-large;
    height: $size-large;
    border-radius: $size-large;

    svg {
      width: 20px;
      height: 20px;
      fill: #5faaf3;

      #saved-board-toggle-on {
        display: inline;
      }

      #saved-board-toggle-off {
        display: none;
      }
    }
  }
}

.saved-board-toggle__saved {
  svg {
    #saved-board-toggle-on {
      display: inline;
    }

    #saved-board-toggle-off {
      display: none;
    }
  }
}

.theme-dark {
  .saved-board-toggle {
    svg {
      fill: #fff;
    }

    &.saved-board-toggle_profile {
      background-color: #616366;

      svg {
        fill: #fff;
      }
    }
  }
}
