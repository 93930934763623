@import '../../../assets/styles/Common.scss';

.board-container {
  display: flex;
  flex-grow: 1;
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
  background-color: #fff;
  border-radius: $border-radius-default;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.2s ease;

  // &.board-container_active-board {
  background: #fff url('./images/bg_board-container.png') 0 0 repeat;

  @media only screen and (-webkit-min-device-pixel-ratio: 1.25),
    only screen and (min-device-pixel-ratio: 1.25),
    only screen and (min-resolution: 1.25dppx) {
    background-image: url('./images/bg_board-container@2x.png');
  }

  @media only screen and (-webkit-min-device-pixel-ratio: 2.25),
    only screen and (min-device-pixel-ratio: 2.25),
    only screen and (min-resolution: 2.25dppx) {
    background-image: url('./images/bg_board-container@3x.png');
  }
  // }

  > .board-container__inner {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
  }
}

.board-gradient {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  background-image: linear-gradient(to top, rgba(255, 255, 255, 0), #fff);
  pointer-events: none;
  border-top-right-radius: $border-radius-default;
  border-top-left-radius: $border-radius-default;
  transition: background 0.2s ease;
}

.theme-dark {
  .board-container {
    background-color: #24282b;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

    // &.board-container_active-board {
    background-image: url('./images/bg_board-container-dark-theme.png');

    @media only screen and (-webkit-min-device-pixel-ratio: 1.25),
      only screen and (min-device-pixel-ratio: 1.25),
      only screen and (min-resolution: 1.25dppx) {
      background-image: url('./images/bg_board-container-dark-theme@2x.png');
    }

    @media only screen and (-webkit-min-device-pixel-ratio: 2.25),
      only screen and (min-device-pixel-ratio: 2.25),
      only screen and (min-resolution: 2.25dppx) {
      background-image: url('./images/bg_board-container-dark-theme@3x.png');
    }
    // }
  }

  .board-gradient {
    background-image: linear-gradient(to top, rgba(36, 40, 43, 0), #24282b);
  }
}
