@import '../../../assets/styles/Common.scss';

.resize-handle {
  user-select: none;
}

.resizable__handle_right {
  top: -35px !important;
  right: 23px !important;
}

.resizable__handle_bottom {
  bottom: 38px !important;
  left: -20px !important;
}

.resizable__handle_bottom-right {
  bottom: 31px !important;
  right: 16px !important;
  width: 40px !important;
  height: 40px !important;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  svg {
    fill: #a8a8a8;
  }
}

.theme-dark {
  .resizable__handle_bottom-right {
    svg {
      fill: #24282b;
    }
  }
}
