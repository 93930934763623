@import '../../../assets/styles/Common.scss';

.saved-boards {
  max-height: 294px;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 30px;

  li {
    display: flex;
    padding: 8px 0;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ededed;
    font-size: 14px;
  }

  a {
    font-weight: 300;
  }
}

.saved-boards__message {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;

  .saved-board-toggle {
    margin: 0 0 10px;
  }

  .link {
    font-size: 14px;
    font-weight: 500;
  }

  @media screen and (min-width: 600px) {
    height: 310px;
  }
}

.saved-boards__button-remove {
  $size: 32px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: $size;
  height: $size;
  margin-right: -8px;

  svg {
    fill: $color-blue;

    #saved-board-toggle-off {
      display: none;
    }
  }

  &:hover,
  &:focus,
  &:active {
    svg {
      fill: #3d82d2;
    }
  }
}

.theme-dark {
  .saved-boards {
    li {
      border-color: #3e3e3e;
    }

    a {
      color: #fff;
    }
  }

  .saved-boards__button-remove {
    svg {
      fill: #fff;
    }

    &:hover,
    &:focus,
    &:active {
      svg {
        fill: rgba(255, 255, 255, 0.8);
      }
    }
  }
}
