$font-stack: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans,
  Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial, sans-serif;

$color-grey-light: #f1f2f3;
$color-grey: #e3e3e3;
$color-grey-dark: #707070;

$color-blue: #0c5ab4;
$color-blue-light: #2e8bf7;
$color-blue-dark: #07366c;

$color-text: #333;

$color-error: #ea4140;
$color-success: #3dc468;
$color-info: #2795f4;

$box-shadow-default: 0 0 6px rgba(0, 0, 0, 0.15);
$border-radius-default: 12px;

$color-toolbar-separator: rgba(255, 255, 255, 0.2);
$toolbar-button-hover-background: rgba(92, 130, 210, 0.3);

$color-input: #c4c4c4;
$color-input-focus: #909090;

$input-background: #fff;
$input-border: $color-input;
$input-border-focus: $color-blue;

// Theme dark
$color-blue-theme-dark: #1dc4f2;
$color-blue-light-theme-dark: #91eeff;
$color-grey-theme-dark: #535759;
$color-grey-dark-theme-dark: #2a2d2f;

$input-background-theme-dark: #363636;
$input-border-theme-dark: #646464;
$input-border-focus-theme-dark: #949494;

$toolbar-button-hover-background-theme-dark: rgba(97, 99, 102, 0.3);

// $color-error-theme-dark: #ff006c;
