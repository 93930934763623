@import '../../../assets/styles/Common.scss';

// @-webkit-keyframes rubberBand {
//   from {
//     -webkit-transform: scale3d(1, 1, 1);
//     transform: scale3d(1, 1, 1);
//   }

//   30% {
//     -webkit-transform: scale3d(1.25, 0.75, 1);
//     transform: scale3d(1.25, 0.75, 1);
//   }

//   40% {
//     -webkit-transform: scale3d(0.75, 1.25, 1);
//     transform: scale3d(0.75, 1.25, 1);
//   }

//   50% {
//     -webkit-transform: scale3d(1.15, 0.85, 1);
//     transform: scale3d(1.15, 0.85, 1);
//   }

//   65% {
//     -webkit-transform: scale3d(0.95, 1.05, 1);
//     transform: scale3d(0.95, 1.05, 1);
//   }

//   75% {
//     -webkit-transform: scale3d(1.05, 0.95, 1);
//     transform: scale3d(1.05, 0.95, 1);
//   }

//   to {
//     -webkit-transform: scale3d(1, 1, 1);
//     transform: scale3d(1, 1, 1);
//   }
// }
// @keyframes rubberBand {
//   from {
//     -webkit-transform: scale3d(1, 1, 1);
//     transform: scale3d(1, 1, 1);
//   }

//   30% {
//     -webkit-transform: scale3d(1.25, 0.75, 1);
//     transform: scale3d(1.25, 0.75, 1);
//   }

//   40% {
//     -webkit-transform: scale3d(0.75, 1.25, 1);
//     transform: scale3d(0.75, 1.25, 1);
//   }

//   50% {
//     -webkit-transform: scale3d(1.15, 0.85, 1);
//     transform: scale3d(1.15, 0.85, 1);
//   }

//   65% {
//     -webkit-transform: scale3d(0.95, 1.05, 1);
//     transform: scale3d(0.95, 1.05, 1);
//   }

//   75% {
//     -webkit-transform: scale3d(1.05, 0.95, 1);
//     transform: scale3d(1.05, 0.95, 1);
//   }

//   to {
//     -webkit-transform: scale3d(1, 1, 1);
//     transform: scale3d(1, 1, 1);
//   }
// }

.offline-notification {
  position: relative;
  margin: 0 18px;
  padding: 7px 10px;
  background-color: $color-blue;
  border-radius: 14px;
  transition: all 0.25s ease;
  opacity: 0;
  white-space: nowrap;

  // -webkit-animation-duration: 1s;
  // animation-duration: 1s;
  // -webkit-animation-duration: 1s;
  // animation-duration: 1s;
  // -webkit-animation-fill-mode: both;
  // animation-fill-mode: both;

  &.offline-notification_visible {
    opacity: 1;
    // -webkit-animation-name: rubberBand;
    // animation-name: rubberBand;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 10px;
    width: 18px;
    height: 14px;
    margin-top: -7px;
    fill: #fff;
  }

  p {
    padding-left: 25px;
    color: #fff;
    line-height: 1;
    font-size: 11px;
    font-weight: 500;
  }
}

.theme-dark {
  .offline-notification {
    background-color: $color-blue-theme-dark;

    svg {
      fill: #24282b;
    }

    p {
      color: #24282b;
    }
  }
}
