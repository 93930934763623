@import '../../../../assets/styles/Common.scss';

.empty-board-tip {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  user-select: none;
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.3;
  transition: opacity 0.35s ease;

  svg {
    width: 92px;
    height: 92px;
    margin-bottom: 8px;

    .empty-note {
      fill: #8ed2ea;
    }

    .empty-note__corner {
      fill: #7ec2ce;
    }
  }

  b {
    display: block;
    font-size: 26px;
    font-weight: 500;
  }
}

.theme-dark {
  .empty-board-tip {
    color: #fff;

    svg {
      .empty-note {
        fill: #fde194;
      }

      .empty-note__corner {
        fill: #f2c96d;
      }
    }
  }
}
