.#{$rt-namespace}__close-button {
  position: absolute;
  top: 14px;
  right: 14px;
  opacity: 0.7;
  transition: 0.15s ease;

  &:hover,
  &:focus {
    opacity: 1;
  }

  svg {
    stroke: #fff;
  }
}

.theme-dark {
  .#{$rt-namespace}__close-button {
    svg {
      stroke: #909090;
    }
  }
}
