@import '../../assets/styles/Common.scss';

$headerHeight: 40px;

.app-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-bottom: 15px;
  user-select: none;
  transition: opacity 0.1s;
  padding-right: 13em;
  min-height: $headerHeight;

  &.app-header_hidden {
    pointer-events: none;
    user-select: none;

    a {
      display: none;
    }

    .app-header__tools {
      margin-right: 168px;
    }
  }

  a {
    display: block;
  }
}

.app-header__main {
  display: flex;
  max-width: 100%;
  flex-direction: row;
  align-items: center;
  height: $headerHeight;

  .editable {
    > div {
      max-width: 100%;
    }
  }
}

.app-header__tools {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  width: 13em;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
