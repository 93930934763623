@import '../../../assets/styles/Forms.scss';

.modal-auth {
  width: 90%;

  @media screen and (min-width: 480px) {
    padding: 50px;
  }
}

@media screen and (min-width: 600px) {
  .modal-auth {
    width: 496px;
  }

  .home-page {
    .react-responsive-modal-modal.modal-auth {
      width: 496px;
      margin-top: 176px;
    }
  }
}
