.#{$rt-namespace}__toast {
  display: flex;
  position: relative;
  min-height: $rt-toast-min-height;
  overflow: hidden;
  box-sizing: border-box;
  margin-bottom: 15px;
  cursor: pointer;
  direction: ltr;

  padding: 15px 30px 15px 15px;
  color: #fff;
  border-radius: 10px;
  box-shadow: $box-shadow-default;
  font-size: 14px;

  &-body {
    display: flex;
    align-items: center;
    position: relative;
    min-height: 45px;
    padding-left: 60px;

    /* :before is the circle */
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 43px;
      height: 43px;
      border-radius: 45px;
      border: 1px solid #fff;
    }

    /* :after is the icon */
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 45px;
      height: 45px;
      background: 50% 50% url('../icons/icon_info.svg') no-repeat;
    }
  }

  &--info {
    background-color: $color-info;

    .Toastify__toast-body {
      &::after {
        background-image: url('../icons/icon_info.svg');
      }
    }
  }

  &--success {
    background-color: $color-success;

    .Toastify__toast-body {
      &::after {
        background-image: url('../icons/icon_success.svg');
      }
    }
  }

  &--error {
    background-color: $color-error;

    .Toastify__toast-body {
      &::after {
        background-image: url('../icons/icon_error.svg');
      }
    }
  }
}

.theme-dark {
  .#{$rt-namespace}__toast {
    color: #333;
    background-color: #f2f2f2;

    p {
      color: #333;
    }

    &-body {
      &::before {
        background-color: $color-info;
      }
    }

    &--info {
      .Toastify__toast-body {
        &::before {
          border-color: $color-info;
          background-color: $color-info;
        }
      }
    }

    &--success {
      .Toastify__toast-body {
        &::before {
          border-color: $color-success;
          background-color: $color-success;
        }
      }
    }

    &--error {
      .Toastify__toast-body {
        &::before {
          border-color: $color-error;
          background-color: $color-error;
        }
      }
    }
  }
}
