@import '../../../assets/styles/Common.scss';

.wall {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  padding: 20px 30px;
  opacity: 0;
  // transition: all 0.2s ease;
  // transform: translate3d(100%, 0, 0);

  &.wall_visible {
    opacity: 1;
    // transform: translate3d(0, 0, 0);
  }
}

.enable-wall-transitions {
  .wall {
    transition: all 0.3s ease;
  }
}
