@import './Common.scss';

label {
  display: block;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 300;
  color: #6e6e6e;
}

input[type='text'],
input[type='email'],
input[type='password'],
input[type='number'],
textarea {
  display: block;
  width: 100%;
  margin: 0;
  box-sizing: border-box;
  padding: 8px 15px;
  outline: none;
  border: 1px solid $input-border;
  border-radius: 3px;
  color: $color-text;
  outline: none;
  font-family: $font-stack;
  appearance: none;
  background-color: $input-background;

  &:focus {
    border-color: $input-border-focus;
  }

  &:-webkit-autofill {
    -webkit-text-fill-color: $color-text;
    box-shadow: 0 0 0px 1000px $input-background inset;
  }
}

input[type='text'],
input[type='email'],
input[type='password'],
input[type='number'] {
  height: 50px;
  font-size: 17px;
  line-height: 50px;
  font-weight: 300;

  &[type='radio'],
  &[type='checkbox'] {
    cursor: pointer;
  }
}

textarea {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 16px;
  line-height: 1.6;
}

.input-container {
  margin-bottom: 20px;

  .input-container {
    margin-bottom: 5px;
  }
}

.input-row {
  input {
    margin-bottom: 20px;
  }

  .input-row__button button {
    width: 100%;
  }

  @media screen and(min-width: 480px) {
    display: flex;
    flex-direction: row;

    input {
      margin-right: 10px;
      margin-bottom: 0;
    }

    .input-row__button {
      min-width: 120px;
    }
  }
}

.input_invalid {
  input[type='text'],
  input[type='email'],
  input[type='password'],
  input[type='number'],
  textarea {
    border-color: $color-error;
  }

  .input-message {
    margin-top: 4px;
    font-size: 12px;
    color: $color-error;
  }
}

.form-error-msg {
  margin-top: 10px;
  font-size: 14px;
  color: $color-error;

  & + .modal-buttons__stack {
    margin-top: 20px;
  }
}

.form-separator {
  padding-top: 20px;
  border-top: 1px solid #e8e8e8;
  transition: border-color 0.2s ease;

  &.form-separator_medium {
    margin-top: 20px;
    padding-top: 30px;
  }

  &.form-separator_large {
    margin-top: 40px;
    padding-top: 30px;
  }
}

.theme-dark {
  input[type='text'],
  input[type='email'],
  input[type='password'],
  input[type='number'],
  textarea {
    background-color: $input-background-theme-dark;
    border: 1px solid $input-border-theme-dark;
    color: #fff;

    &:focus {
      border-color: $input-border-focus-theme-dark;
    }

    &:-webkit-autofill {
      -webkit-text-fill-color: #fff;
      box-shadow: 0 0 0px 1000px $input-background-theme-dark inset;
    }
  }

  label,
  p {
    color: #fff;
  }

  .input_invalid {
    input[type='text'],
    input[type='email'],
    input[type='password'],
    input[type='number'],
    textarea {
      border-color: $color-error;
    }
  }

  .form-error-msg {
    color: $color-error;
  }

  .form-separator {
    border-color: #646464;
  }
}
