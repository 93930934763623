@import '../../assets/styles/Common.scss';

.columns {
  display: flex;
  // height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: row;
  // touch-action: manipulation;
}

.columns-disable-user-select {
  user-select: none;
}

.column {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: 147px;
  padding-top: 20px;
  justify-items: center;
  align-items: flex-start;
  background: transparent url('./assets/bg_columnBorder.svg') 0 0 repeat-y;
  // touch-action: manipulation;

  &:first-of-type {
    background: none;
  }
}

.theme-dark {
  .column:not(:first-of-type) {
    background-image: url('./assets/bg_columnBorder-dark-theme.svg');
  }
}

.column__title {
  position: relative;
  z-index: 2147483645;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-items: center;
  margin: 0 7px 0 8px;
  font-size: 14px;
  line-height: 2em;
  text-align: center;
  user-select: none;
  transform: translate3d(0, 0, 0);

  > div {
    position: relative;
    min-width: 80px;
    margin: 0 auto;
    padding: 0 26px;
    background-color: $color-grey-light;
    border-radius: 14px;

    &:hover .button-remove {
      display: block;
    }
  }

  .button-remove {
    display: none;
    position: absolute;
    top: 0.5em;
    right: 8px;

    &:hover,
    &:focus,
    &:active {
      display: block;
    }
  }
}

.touch-screen {
  .column__title {
    transition: transform 0.05s ease;
  }
}

.theme-dark {
  .column__title {
    > div {
      color: #fff;
      background-color: #393c40;
    }
  }
}
