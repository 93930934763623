@import '../../../assets/styles/Common.scss';
@import '../../../assets/styles/Forms.scss';

.create-board {
  position: relative;
  padding: 30px;
  background-color: #fff;
  transition: background-color 0.2s ease;
  border-radius: $border-radius-default;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  .create-board__input {
    margin: 0 0 20px;

    input {
      width: 100%;
    }
  }

  .text-generic {
    text-align: center;
  }

  .button-generic {
    width: 100%;
    margin-bottom: 20px;
  }

  @media screen and (min-width: 480px) {
    padding: 50px;
  }
}

.create-board__notes {
  position: absolute;
  top: -45px;
  right: -34px;
  z-index: 2;
  width: 184px;
  height: 150px;
}

.theme-dark {
  .create-board {
    background-color: $color-grey-dark-theme-dark;
  }
}
