.preloader {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  text-align: center;

  width: 80px;
  height: 80px;
}

#loader-8 {
  animation: loader-8_c_o 2400ms linear infinite normal forwards;
}

@keyframes loader-8_c_o {
  0% {
    opacity: 1;
  }
  37.500000% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  87.500000% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
#loader-7 {
  animation: loader-7_c_o 2400ms linear infinite normal forwards;
}
@keyframes loader-7_c_o {
  0% {
    opacity: 1;
  }
  37.500000% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  87.500000% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
#loader-6 {
  animation: loader-6_c_o 2400ms linear infinite normal forwards;
}
@keyframes loader-6_c_o {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  37.500000% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  87.500000% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
#loader-5 {
  animation: loader-5_c_o 2400ms linear infinite normal forwards;
}
@keyframes loader-5_c_o {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  37.500000% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  87.500000% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
#loader-4 {
  animation: loader-4_c_o 2400ms linear infinite normal forwards;
}
@keyframes loader-4_c_o {
  0% {
    opacity: 1;
  }
  12.500000% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  62.500000% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
#loader-3 {
  animation: loader-3_c_o 2400ms linear infinite normal forwards;
}
@keyframes loader-3_c_o {
  0% {
    opacity: 1;
  }
  12.500000% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  62.500000% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
#loader-2 {
  animation: loader-2_c_o 2400ms linear infinite normal forwards;
}
@keyframes loader-2_c_o {
  0% {
    opacity: 1;
  }
  12.500000% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  62.500000% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
#loader-1 {
  animation: loader-1_c_o 2400ms linear infinite normal forwards;
}
@keyframes loader-1_c_o {
  0% {
    opacity: 1;
  }
  12.500000% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  62.500000% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
