.#{$rt-namespace}__toast-container {
  z-index: $rt-z-index;
  -webkit-transform: translate3d(0, 0, #{$rt-z-index}px);
  position: fixed;

  width: $rt-toast-width;
  box-sizing: border-box;

  &--top-left {
    top: 20px;
    left: 20px;
  }
  &--top-center {
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
  &--top-right {
    top: 20px;
    right: 20px;
  }
  &--bottom-left {
    bottom: 20px;
    left: 20px;
  }
  &--bottom-center {
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
  &--bottom-right {
    bottom: 20px;
    right: 20px;
  }
}

@media #{$rt-mobile} {
  .#{$rt-namespace}__toast-container {
    width: 94vw;
    padding: 0;
    left: 3vw;
    margin: 0;

    &--top-left,
    &--top-center,
    &--top-right {
      top: 20px;
      transform: translateX(0);
    }

    &--bottom-left,
    &--bottom-center,
    &--bottom-right {
      bottom: 0;
      transform: translateX(0);
    }

    &--rtl {
      right: 3vw;
      left: initial;
    }
  }
}
