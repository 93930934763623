@import '../../../../assets//styles/Common.scss';

$rt-namespace: 'Toastify';
$rt-toast-width: 336px;
$rt-toast-min-height: 45px;
// $rt-toast-max-height: 100px;

// $rt-color-default: #fff;
// $rt-color-dark: $color-text;
// $rt-color-info: $color-info;
// $rt-color-success: $color-success;
// $rt-color-warning: #f1c40f;
// $rt-color-error: $color-error;

// $rt-color-progress-default: linear-gradient(
//   to right,
//   #4cd964,
//   #5ac8fa,
//   #007aff,
//   #34aadc,
//   #5856d6,
//   #ff2d55
// );

// $rt-color-progress-dark: #bb86fc;
$rt-mobile: 'only screen and (max-width : 480px)';
$rt-z-index: 9999;
