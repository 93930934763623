.user-thumbnail {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  margin: 0 auto 5px;
  background-color: #fff;
  border-radius: 44px;

  &.user-thumbnail_dark {
    background-color: #535759;
  }
}
