@import '../../../../assets/styles/Common.scss';

.input_password {
  position: relative;

  .password-hiddenword-button {
    position: absolute;
    top: 10px;
    right: 1px;
    bottom: 10px;
    width: 60px;
    border-left: 1px solid #dedede;
    background-color: $input-background;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    svg {
      width: 15px;
      height: 15px;
    }

    #password-visible {
      display: none;
    }
  }

  .password-hiddenword-button__active {
    #password-hidden {
      display: none;
    }

    #password-visible {
      display: block;
    }
  }

  svg {
    fill: #333;
  }
}

.theme-dark {
  .input_password {
    .password-hiddenword-button {
      background-color: $input-background-theme-dark;
      border-left-color: #585858;
    }

    .password-hiddenword-button__active {
      opacity: 1;
    }

    svg {
      fill: $color-blue-theme-dark;
    }
  }
}
