@import '../../../assets/styles/Common.scss';

.header-button-round {
  $size: 34px;

  margin: 0;
  background-color: $color-blue;
  display: flex;
  width: $size;
  height: $size;
  position: relative;
  overflow: hidden;
  border-radius: $size;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
  -webkit-tap-highlight-color: transparent;

  &:hover,
  &:focus,
  &:active {
    background-color: #3d82d2;
  }

  & + .header-button-round {
    margin-left: 20px;
  }
}

.theme-dark {
  .header-button-round {
    background-color: $color-grey-dark-theme-dark;

    &:hover,
    &:focus,
    &:active {
      background-color: #616366;
    }
  }
}
