@import '../../../assets/styles/Common.scss';

.toolbar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 2147483646;
  width: 60px;
  background: linear-gradient($color-blue 30%, $color-blue-dark);
  border-top-left-radius: $border-radius-default;
  border-bottom-left-radius: $border-radius-default;
  user-select: none;
  transition: background-color 0.2s ease;
}

.relative-container {
  position: relative;
}

.theme-dark {
  .toolbar {
    background: #393c40;
  }
}
