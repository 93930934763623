@import '../../assets/styles/Common.scss';

.modal-title {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 30px;

  > span {
    display: block;
    width: 100%;
    padding: 0.5em 1em;
    border-bottom: 1px solid #e4e4e4;
    font-size: 14px;
    font-weight: 300;
    color: #6e6e6e;
    text-align: center;
  }
}

.theme-dark {
  .modal-title span {
    color: #fff;
  }
}
