@import './Common.scss';

// http://meyerweb.com/eric/tools/css/reset/
// v2.0 | 20110126
// License: none (public domain)
html,
body,
div,
span,
h1,
h2,
h3,
h4,
// h5,
// h6,
p,
// blockquote,
// pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
// legend,
// table,
// caption,
// tbody,
// tfoot,
// thead,
// tr,
// th,
// td,
article,
// aside,
canvas,
details,
embed,
// figure,
// figcaption,
footer,
header,
// hgroup,
menu,
nav,
output,
// summary,
// time,
// mark,
// audio,
// video
section {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
ol,
ul {
  list-style: none;
}
// table {
//   border-collapse: collapse;
//   border-spacing: 0;
// }

html,
body {
  height: 100%;
  min-height: 100%;
}

body {
  padding: 0;
  font: 16px/1.5 $font-stack;
  color: #333;
  background-color: $color-grey-light;
  transition: background-color 0.2s ease;

  &.theme-dark {
    background-color: #818181;
  }
}

a,
button.link {
  color: $color-blue;
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    color: $color-blue-light;
    text-decoration: underline;
  }
}

.link_icon {
  line-height: 16px;

  svg {
    margin-right: 10px;
    fill: $color-blue;
    vertical-align: top;
  }
}

.theme-dark {
  a,
  button.link {
    color: $color-blue-theme-dark;

    &:hover,
    &:focus,
    &:active {
      color: $color-blue-light-theme-dark;
    }
  }

  .link_icon {
    svg {
      fill: $color-blue-theme-dark;
    }
  }
}

abbr {
  text-decoration: none;
}

button {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  background: none;
  cursor: pointer;
}

header {
  text-align: center;
}

footer {
  text-align: center;
}

b {
  font-weight: 500;
}

.text-centered {
  text-align: center;
}

.text-generic {
  margin-bottom: 10px;
  font-size: 14px;
  color: #5f5f5f;
  font-weight: 300;

  a,
  button {
    font-size: 14px;
    font-weight: 500;
  }

  p {
    margin-bottom: 10px;
  }

  // h2,
  // h3,
  // h4,
  // h5,
  // h6 {
  //   margin-bottom: 0.25em;
  //   font-weight: 500;
  // }

  ul {
    margin-bottom: 1em;
    list-style-type: disc;
  }
}

.top-spacing-medium {
  margin-top: 20px;
}

.bottom-spacing-medium {
  margin-bottom: 20px;
}

.bottom-spacing-large {
  margin-bottom: 30px;
}

.reset-margin {
  margin-bottom: 0;
}

.theme-dark {
  .text-generic {
    color: #fff;
  }
}

.firebase-emulator-warning {
  display: none;
}

.hide {
  position: absolute;
  top: -9999em;
  left: -9999em;
  margin: 0;
  padding: 0;
}

.inline-text-separator {
  position: relative;

  &:before {
    content: ' | ';
    margin: 0 10px;
    color: #9f9f9f;
  }
}

#root {
  height: 100%;
}

.__react_component_tooltip {
  padding: 5px 15px !important;
  font-size: 12px !important;
  z-index: 2147483647 !important;
}
