@import '../../../assets/styles/Common.scss';

.cookies-notification {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  right: 20px;
  left: 20px;
  bottom: 20px;
  z-index: 9999;
  padding: 20px;
  background-color: $color-grey-light;
  border: 1px solid #c6c6c6;
  border-radius: $border-radius-default;
  user-select: none;
  transition: all 0.25s ease;
  opacity: 0;

  // animation-duration: 1s;
  // animation-duration: 1s;
  // animation-fill-mode: both;

  &.cookies-notification_visible {
    opacity: 1;
    // animation-name: rubberBand;
  }

  p {
    position: relative;
    margin-bottom: 20px;
    font-size: 12px;
    color: #3b3e3f;
  }

  .button-generic {
    width: 100%;
    min-width: 80px;
  }

  @media screen and (min-width: 600px) {
    flex-direction: row;
    width: 700px;
    max-width: 70%;
    left: auto;

    p {
      min-height: 40px;
      margin-right: 30px;
      margin-bottom: 0;
      padding-left: 60px;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -20px;
        width: 40px;
        height: 40px;
        background: url('./img_cookie.png') 0 0 no-repeat;
        background-size: 40px;

        @media only screen and (-webkit-min-device-pixel-ratio: 1.25),
          only screen and (min-device-pixel-ratio: 1.25),
          only screen and (min-resolution: 1.25dppx) {
          background-image: url('./img_cookie@2x.png');
        }

        @media only screen and (-webkit-min-device-pixel-ratio: 2.25),
          only screen and (min-device-pixel-ratio: 2.25),
          only screen and (min-resolution: 2.25dppx) {
          background-image: url('./img_cookie@3x.png');
        }
      }
    }

    .button-generic {
      width: auto;
    }
  }
}

.theme-dark {
  .cookies-notification {
    background-color: #818181;
    border-color: #5e5e5e;

    p {
      color: #fff;
    }
  }
}

// @mixin timing-function {
//   animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
// }

// @keyframes cookies__bounceInRight {
//   from,
//   60%,
//   75%,
//   90%,
//   to {
//     @include timing-function;
//   }
//   from {
//     opacity: 0;
//     transform: translate3d(3000px, 0, 0);
//   }
//   60% {
//     opacity: 1;
//     transform: translate3d(-25px, 0, 0);
//   }
//   75% {
//     transform: translate3d(10px, 0, 0);
//   }
//   90% {
//     transform: translate3d(-5px, 0, 0);
//   }
//   to {
//     transform: none;
//   }
// }

// @keyframes cookies__bounceOutRight {
//   20% {
//     opacity: 1;
//     transform: translate3d(-20px, 0, 0);
//   }
//   to {
//     opacity: 0;
//     transform: translate3d(2000px, 0, 0);
//   }
// }

// .cookies__bounce-enter {
//   animation-name: cookies__bounceInRight;
// }

// .cookies__bounce-exit {
//   animation-name: cookies__bounceOutRight;
// }
