@import '../../../assets/styles/Common.scss';

.home {
  margin: 10px 20px 0;
  opacity: 0;

  &.home_visible {
    opacity: 1;
  }

  .footer {
    text-align: center;
  }
}

.enable-wall-transitions {
  .home {
    transition: opacity 0.3s ease;
  }
}

.home-page-background {
  display: none;
}

@media screen and (min-width: 480px) and (min-height: 550px) {
  .home {
    position: relative;
    top: 50%;
    left: 50%;
    width: 456px;
    margin: -20px 0 0;
    transform: translate3d(-50%, -50%, 0);
  }
}

@media screen and (min-width: 640px) {
  .home-page-background {
    display: block;

    svg {
      position: absolute;
      top: -152px;
      left: -207px;
      fill: #ededed;
      transition: fill 0.2s ease;
    }
  }

  .theme-dark {
    .home-page-background svg {
      fill: #898989;
    }
  }
}
