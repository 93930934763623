@import '../../../../../assets/styles/Common.scss';

.selected-notes-toolbar {
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 2147483647;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: $color-blue;
  border-radius: 4px;
  transition: transform 0.2s;

  button {
    min-width: 35px;
    padding: 10px;
    text-align: center;
    vertical-align: center;

    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    svg {
      display: block;
      margin: 0 auto;
      fill: #fff;
    }

    &:hover,
    &:focus,
    &:active {
      background: rgba($color: #fff, $alpha: 0.1);
    }
  }
}

.theme-dark {
  .selected-notes-toolbar {
    background: #6c6f71;

    button {
      svg {
        fill: #fff;
      }
    }
  }
}
