.button-remove {
  display: block;
  border: none;
  background: none;
  opacity: 0.5;

  &:hover,
  &:focus,
  &:active {
    opacity: 1;
  }

  .button-remove__inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
