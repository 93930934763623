@import '../../assets/styles/Common.scss';

.footer {
  position: absolute;
  bottom: -33px;
  left: 0;
  width: 100%;
  font-size: 12px;
  line-height: 1;
  color: $color-grey-dark;
  user-select: none;

  .link {
    font-size: 12px;
    font-weight: 500;
  }
}

.theme-dark {
  .footer {
    color: #fff;

    .link {
      color: #fff;

      &:hover,
      &:focus,
      &:active {
        color: #fff;
      }
    }
  }
}
