.editable {
  > div {
    outline: none;
    word-break: break-all;
  }

  &.editable_editing {
    > div {
      user-select: text;
    }
  }
}
